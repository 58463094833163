import styled from 'styled-components';

import { IconPause } from '@/components/icon/IconPause';
import { IconPlay } from '@/components/icon/IconPlay';
import Theme from '../../../styles/Theme';

interface Props {
	light?: boolean;
	playing: boolean;
	onClick: () => void;
}

const StyledButton = styled.button`
	border: none;
	background: ${(props) => props.theme.playerMainColor};
	cursor: pointer;
	outline: 0;
	position: relative;

	width: ${(props) => 64 / props.theme.fontSizeBase}rem;
	height: ${(props) => 64 / props.theme.fontSizeBase}rem;
	border-radius: ${(props) => 64 / props.theme.fontSizeBase}rem;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledButtonLight = styled.button`
	border: none;
	cursor: pointer;
	background: none;
	outline: 0;
	position: relative;

	display: inline-flex;
	align-items: center;
	justify-content: center;
`;

export const PlayPauseButton = ({ playing, onClick, light = false }: Props) => {
	if (light) {
		return (
			<StyledButtonLight onClick={onClick}>
				{ playing && <IconPause width={24} height={24} color={Theme.textPrimary} /> }
				{ !playing && <IconPlay width={24} height={24} color={Theme.textPrimary} /> }
			</StyledButtonLight>
		);
	}

	return (
		<StyledButton onClick={onClick}>
			{ playing && <IconPause width={24} height={24} color={Theme.white} /> }
			{ !playing && <IconPlay width={24} height={24} color={Theme.white} /> }
		</StyledButton>
	);
};
