import { Store } from 'pullstate';

interface ResponsiveStoreProps {
	headerHeight: number;
	footerHeight: number;
	captionHeight: number;
}

export const ResponsiveStore = new Store<ResponsiveStoreProps>({
	headerHeight:  0,
	footerHeight:  0,
	captionHeight: 0,
});
