import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconExclamationPoint = memo<SVGProps<SVGSVGElement>>(
	(props: SVGProps<SVGSVGElement>) => (
		<svg {...props} viewBox="0 0 112 115" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_334_15203)">
				<path d="M46.9411 25.4644C51.8823 24.6429 56.8234 24.6429 61.7646 23.8215C61.7646 23.0001 60.9411 22.1786 60.1176 20.5358C52.7058 36.9644 46.9411 54.2144 43.647 71.4644C43.647 74.7501 47.7646 75.5715 47.7646 73.1072C51.0587 55.8572 55.9999 38.6072 63.4117 23.0001C64.2352 21.3572 63.4117 19.7144 61.7646 19.7144C55.9999 20.5358 51.0587 20.5358 45.294 21.3572C43.647 22.1786 44.4705 26.2858 46.9411 25.4644Z" fill={props.color}/>
				<path d="M43.647 23C39.5294 38.6072 45.2941 54.2143 43.647 69.8215C43.647 72.2857 47.7646 72.2857 47.7646 69.8215C49.4117 55.0357 43.647 39.4286 47.7646 24.6429C48.5882 22.1786 44.4705 20.5357 43.647 23Z" fill={props.color}/>
				<path d="M45.2942 82.9642C43.6472 83.7856 42.8236 84.6071 42.0001 86.2499C41.1766 87.8928 40.353 89.5356 41.1766 91.1785C41.1766 92.8214 42.8236 94.4642 44.4707 95.2856C45.2942 95.2856 46.1177 95.2856 46.9413 95.2856C47.7648 95.2856 48.5883 94.4642 49.4119 94.4642C50.2354 93.6428 50.2354 91.9999 49.4119 91.1785C48.5883 90.3571 47.7648 90.3571 46.1177 91.1785C46.9413 90.3571 46.1177 91.1785 46.1177 91.1785C46.1177 91.1785 46.9413 91.1785 46.1177 91.1785C46.9413 91.1785 46.1177 91.1785 46.1177 91.1785C46.1177 91.1785 46.1177 91.9999 46.1177 91.1785C46.1177 91.1785 46.1177 91.1785 46.9413 90.3571C46.9413 90.3571 47.7648 90.3571 47.7648 89.5356C48.5883 88.7142 49.4119 87.8928 48.5883 87.0714C47.7648 82.9642 46.1177 82.1428 45.2942 82.9642Z" fill={props.color}/>
				<path d="M7.41176 68.9997C0.823529 93.6426 25.5294 115 48.5882 115C74.9412 115 98.8235 92.8212 107.882 68.9997C117.765 42.714 107.882 9.03546 79.0588 1.6426C65.8824 -0.821683 50.2353 -0.821684 37.8824 4.10689C29.6471 7.3926 22.2353 13.964 16.4706 21.3569C6.58823 34.4997 0 51.7497 0 68.1783C0 70.6426 4.11765 70.6426 4.11765 68.1783C4.11765 56.6783 8.23529 44.3569 13.1765 34.4997C21.4118 18.0712 34.5882 6.57117 52.7059 4.92832C67.5294 4.10689 81.5294 4.92832 93.0588 14.7855C102.941 23.8212 107.059 36.1426 107.059 49.2855C106.235 74.7497 87.2941 100.214 63.4118 108.428C51.0588 112.535 37.0588 110.893 26.3529 103.5C15.6471 96.1069 8.23529 82.964 11.5294 69.8212C12.3529 67.3569 8.23529 66.5355 7.41176 68.9997Z" fill={props.color}/>
			</g>
			<defs>
				<clipPath id="clip0_334_15203">
					<rect width="112" height="115" fill="white"/>
				</clipPath>
			</defs>
		</svg>

	),
);
