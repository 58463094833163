import { RELEASE_CHANNEL } from '../env';
import { ReleaseChannels } from '../typings/releaseChannels';

import { LoggerLib, Levels } from './LoggerLib';

const logLevel: { [key: string]: Levels } = {
	[ReleaseChannels.DEVELOPMENT]: Levels.TRACE,
	[ReleaseChannels.STAGING]:     Levels.INFO,
	[ReleaseChannels.PRODUCTION]:  Levels.SILENT,
};

export const logger = new LoggerLib(logLevel[RELEASE_CHANNEL]);
