import { useRouter } from 'next/router';
import {
	FunctionComponent, PropsWithChildren, useEffect,
} from 'react';
import { MapProvider } from 'react-map-gl';
import { createGlobalStyle, css, ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';

import { AudioPlayerProvider } from 'react-hl-audio';
import Theme from '../styles/Theme';
import { useDevice } from '../custom/useDevice';
import { localStorageGetItem, LocalStorageKeys } from '../data/LocalStorage';
import { MapStore } from '../data/MapStore';
import { PlayerStore } from '../data/PlayerStore';
import { Client } from '../models/Client';

import { Onboarding } from './map/onboarding/Onboarding';
import ClassicPlayer from './audio/ClassicPlayer';
import { ClientProvider, initializeClient } from './context/ClientContext';

interface Props {
	client: Client | null;
	isMap: boolean;
}

const GlobalStyle = createGlobalStyle<{ isDark: boolean }>`
	html, body {
		// height: 100vh;

		min-height: 100vh;

		@media only screen and (max-width: 767px) {
			/* mobile viewport bug fix */
			height: 100%;
			min-height: -webkit-fill-available;
		}

		margin: 0;
		padding: 0;

		// font-size: 10px;
		font-family: 'Plus Jakarta Sans', sans-serif;
		color: ${(props) => props.theme.textPrimary};

		${(props) => props.isDark && css`
			background: ${props.theme.black};
		`}

		@media only screen and (max-width : 321px) {
			font-size: ${(props) => props.theme.fontSizeSmall}px;
		}
	}

	#__next {
		// height: 100vh;
		display: flex;
		flex-direction: column;

		min-height: 100vh;

		@media only screen and (max-width: 767px) {
			/* mobile viewport bug fix */
			min-height: -webkit-fill-available;
		}
	}

	* {
		box-sizing: border-box;
		font-family: 'Plus Jakarta Sans', sans-serif;

		margin: 0;
		padding: 0;
	}
`;

export const Layout: FunctionComponent<PropsWithChildren<Props>> = ({
	children, isMap, client,
}: PropsWithChildren<Props>) => {
	const router = useRouter();
	const audio = PlayerStore.useState((s) => s.audio);
	const {
		displayOnboarding,
	} = MapStore.useState((s) => s);
	const device = useDevice();

	useEffect(() => {
		if (isMap) {
			const onboarding = localStorageGetItem<boolean>(LocalStorageKeys.ONBOARDING, true);
			MapStore.update((s) => {
				s.displayOnboarding = onboarding;
			});
		}
	}, [isMap]);

	return (
		<ThemeProvider theme={Theme}>
			<ModalProvider>
				<MapProvider>
					<ClientProvider initialClient={initializeClient(client?.attributes.domain || '')}>
						<GlobalStyle isDark={audio === null && router.pathname === '/parcours/[slug]/capsule/[index]'} />
						{ device !== undefined && <>
							{children}
							{/* { audio && <Player fixed={router.pathname !== '/parcours/[slug]/capsule/[index]'}/> } */}
							{ audio && <AudioPlayerProvider>
								<ClassicPlayer
									fixed={router.pathname !== '/parcours/[slug]/capsule/[index]'}
								/>
							</AudioPlayerProvider> }
							{ displayOnboarding && client && isMap && <Onboarding/> }
						</> }
					</ClientProvider>
				</MapProvider>
			</ModalProvider>
		</ThemeProvider>
	);
};
