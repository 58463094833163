import { FunctionComponent } from 'react';
import { SpinnerCircular } from 'spinners-react';
import styled from 'styled-components';

interface SpinnerProps {
	className?: string;
}

const StyledSpinnerCircular = styled(SpinnerCircular)`
	display: block;
	margin-left: auto;
	margin-right: auto;
`;

export const Spinner: FunctionComponent<SpinnerProps> = ({ className }: SpinnerProps) => (
	<StyledSpinnerCircular
		className={className}
		size={50}
		thickness={100}
		color="rgba(255, 255, 255, 1)"
		secondaryColor="rgba(0, 0, 0, 0.44)"
		speed={100}
	/>
);
