import { Props } from 'next/script';
import { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledText = styled.p`
	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;

	// line-height: ${(props) => 20 / props.theme.fontSizeBase}rem;

	color: ${(props) => props.theme.black};
	text-align: center;

	// margin: ${(props) => 17 / props.theme.fontSizeBase}rem 0 0 0;
	margin: 0;
	padding: 0;

	white-space: pre-line; // To support \n
`;

export const OnboardingText: FunctionComponent<PropsWithChildren<Props>> = ({ children }) => (
	<StyledText>{ children }</StyledText>
);
