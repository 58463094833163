import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconPrev = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.23136 2.48876C4.29136 5.54876 4.34136 8.59876 4.39136 11.6588C4.39136 11.9288 4.62136 12.1488 4.89136 12.1588C6.25136 12.1888 7.35136 11.4488 8.38136 10.6488C9.52136 9.75876 10.6914 8.96876 11.9614 8.26876C14.4114 6.90876 16.9914 5.71876 18.9814 3.69876C18.7014 3.53876 18.4214 3.37876 18.1414 3.20876C17.3914 5.60876 17.5014 8.11876 17.5914 10.5988C17.6914 13.2488 17.8014 15.8988 17.9014 18.5488C18.1514 18.4088 18.4014 18.2588 18.6514 18.1188C13.7814 15.9888 10.3414 11.4588 5.19136 9.83876C4.85136 9.73876 4.60136 10.0188 4.56136 10.3288C4.10136 13.9088 3.92136 17.5188 4.03136 21.1288C4.05136 21.7688 5.05136 21.7688 5.03136 21.1288C4.92136 17.5188 5.10136 13.9088 5.56136 10.3288C5.35136 10.4888 5.14136 10.6488 4.93136 10.8088C9.97136 12.3988 13.3614 16.8888 18.1514 18.9888C18.4714 19.1288 18.9214 18.9688 18.9014 18.5588C18.8014 15.9888 18.7014 13.4188 18.6014 10.8588C18.5014 8.38876 18.3614 5.87876 19.1014 3.48876C19.2514 3.00876 18.6114 2.64876 18.2614 2.99876C16.3814 4.90876 13.9314 6.03876 11.6114 7.31876C10.4914 7.93876 9.41136 8.59876 8.40136 9.37876C7.36136 10.1788 6.27136 11.1988 4.88136 11.1688C5.06136 11.3288 5.22136 11.4988 5.39136 11.6588C5.34136 8.59876 5.29136 5.53876 5.23136 2.47876C5.22136 1.83876 4.22136 1.83876 4.23136 2.48876Z" fill={props.color} stroke={props.color} strokeWidth="0.4"/>
	</svg>
));
