interface DurationProps {
	className?: string;
	seconds: number;
}

function pad(string: number) {
	return (`0${string}`).slice(-2);
}

function format(seconds: number): string {
	const date = new Date(seconds * 1000);
	const hh = date.getUTCHours();
	const mm = date.getUTCMinutes();
	const ss = pad(date.getUTCSeconds());
	if (hh) {
		return `${hh}:${pad(mm)}:${ss}`;
	}
	return `${mm}:${ss}`;
}

export const Duration = ({ className, seconds }: DurationProps) => (
	<time dateTime={`P${Math.round(seconds)}S`} className={className}>
		{format(seconds)}
	</time>
);
