import { Store } from 'pullstate';

import { StrapiMedia } from './Strapi';

interface PlayerAudio {
	title: string;
	name: string;
	url: string;
	image: StrapiMedia;
}

interface PlayerStoreProps {
	key: number;
	currentExperienceSlug: string | null;
	currentMediaIndex: string | null;
	currentCapsuleSlug: string | null;

	audio: PlayerAudio | null;

	prevMediaIndex: string | null;
	nextMediaIndex: string | null;

	prevCapsuleSlug: string | null;
	nextCapsuleSlug: string | null;

	capsuleAnalyticsEvent: string | null;
}

export const PlayerStore = new Store<PlayerStoreProps>({
	key:                   0,
	currentExperienceSlug: null,
	currentMediaIndex:     null,
	currentCapsuleSlug:    null,

	audio: null,

	prevMediaIndex: null,
	nextMediaIndex: null,

	prevCapsuleSlug: null,
	nextCapsuleSlug: null,

	capsuleAnalyticsEvent: null,
});
