import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconPointerFilledSVG = memo<SVGProps<SVGSVGElement>>(
	(props: SVGProps<SVGSVGElement>) => (
		<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.052 10.8952C8.51733 10.4753 5.9827 10.1603 3.44806 9.84529C3.55367 10.0553 3.55367 10.2653 3.65928 10.5803C4.60977 9.84529 5.77148 9.42531 6.93319 8.90034C8.30611 8.37536 9.67904 7.85038 11.052 7.4304C13.3754 6.69543 15.5932 5.75047 17.811 4.91051C18.7615 4.59553 19.712 4.17554 20.7681 3.86056C20.5568 3.65057 20.4512 3.54557 20.24 3.33558C19.0783 6.80043 17.9166 10.3703 16.4381 13.6251C15.4876 15.83 14.5371 18.0349 13.481 20.3448C13.6922 20.3448 14.009 20.4498 14.2203 20.4498C13.2698 17.9299 12.9529 15.3051 12.8473 12.6802C12.8473 12.1552 12.0025 12.1552 12.0025 12.6802C12.2137 15.41 12.4249 18.1399 13.481 20.6598C13.5866 20.9748 14.1146 21.1848 14.2203 20.7648C15.4876 17.7199 16.9661 14.7801 18.1278 11.6302C18.8671 9.53031 19.6064 7.4304 20.3456 5.33049C20.5568 4.70052 20.7681 4.17554 20.9793 3.54557C21.0849 3.23059 20.7681 2.9156 20.4512 3.0206C18.2334 3.86056 15.91 4.70052 13.6922 5.54048C11.4744 6.48544 9.15099 7.11541 6.82758 8.06037C5.56026 8.58535 4.18733 9.11033 3.13123 9.95029C2.81441 10.1603 3.13123 10.5803 3.44806 10.6853C5.9827 11.0002 8.41172 11.3152 10.9464 11.7352C11.3688 11.8402 11.58 11.0002 11.052 10.8952Z" fill={props.color}/>
		</svg>
	),
);
