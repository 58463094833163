import { Props } from 'next/script';
import { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledTitle = styled.p`
	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: ${(props) => 18 / props.theme.fontSizeBase}rem;
	// line-height: ${(props) => 20 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.black};
	text-align: center;
	margin: 0;
	padding: 0;
`;

export const OnboardingTitle: FunctionComponent<PropsWithChildren<Props>> = ({ children }) => (
	<StyledTitle>{ children }</StyledTitle>
);
