import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { IconSeekBackward } from '@/components/icon/IconSeekBackward';
import Theme from '../../../styles/Theme';

interface Props {
	onClick: () => void;
}

const StyledButton = styled.button`
	border: none;
	background: none;
	cursor: pointer;
	outline: 0;
	position: relative;
`;

export const SeekBackwardButton: FunctionComponent<Props> = ({ onClick }: Props) => (
	<StyledButton onClick={onClick}>
		<IconSeekBackward width={24} height={24} color={Theme.black} />
	</StyledButton>
);
