/* eslint-disable max-len */
import { RELEASE_CHANNEL } from '@/env';
import { ReleaseChannels } from '@/typings/releaseChannels';
import { sendGTMEvent } from '@next/third-parties/google';
import {
	GtmEvent, GtmEventDemarrage, GtmEventPlayer, GtmEventSlideCapsule, GtmEventContact, GtmEventMusair, GtmEventPageView, GtmEventSocial,
} from './gtm.model';

const gtmEventWrapper = (data: GtmEvent) => {
	if (RELEASE_CHANNEL !== ReleaseChannels.DEVELOPMENT) {
		sendGTMEvent({
			...data,
		});
	}
};

export const gtmPageView = (pathname: string, pageTitle: string, client: string) => {
	if (RELEASE_CHANNEL !== ReleaseChannels.DEVELOPMENT) {
		sendGTMEvent({
			event:       'page_view',
			event_group: 'pageviews',
			page:        {
				url:   pathname,
				title: pageTitle,
			},
			client,
		} as GtmEventPageView);
	}
};

export const gtmEventSlideCapsule = (data: GtmEventSlideCapsule) => { gtmEventWrapper(data); };
export const gtmEventDemarrage = (data: GtmEventDemarrage) => { gtmEventWrapper(data); };
export const gtmEventPlayer = (data: GtmEventPlayer) => { gtmEventWrapper(data); };

export const gtmEventMusair = (data: GtmEventMusair) => { gtmEventWrapper(data); };
export const gtmEventSocial = (data: GtmEventSocial) => { gtmEventWrapper(data); };
export const gtmEventContact = (data: GtmEventContact) => { gtmEventWrapper(data); };
