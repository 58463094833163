import {
	PropsWithChildren, createContext, useContext, useState,
} from 'react';

interface ClientContextProps {
	client: string;
	setClient: (client: string) => void;
}

const ClientContext = createContext<ClientContextProps | undefined>(undefined);

interface ClientProviderProps extends PropsWithChildren {
	initialClient: string;
}

export const ClientProvider = ({ children, initialClient }: ClientProviderProps) => {
	const [client, setClient] = useState(initialClient);

	return (
		<ClientContext.Provider value={{ client, setClient }}>
			{children}
		</ClientContext.Provider>
	);
};

export const useClient = (): ClientContextProps => {
	const context = useContext(ClientContext);
	if (context === undefined) {
		throw new Error('useClient must be used within a ClientProvider');
	}
	return context;
};

export const initializeClient = (domain: string): string => domain.replace('.musair.fr', '');
