import { RELEASE_CHANNEL } from './env';
import { frontendUrls } from './typings/releaseChannels';
import { DomainEnum } from './utils/domains';

export const bovarySeo = {
	title:       'Musair',
	description: 'Musez avec Emma & Musair - 5 parcours sonores autour de Madame Bovary',

	canonical: 'https://expomadamebovary.musair.fr',

	openGraph: {
		type:        'website',
		title:       'Musair',
		description: 'Musez avec Emma & Musair - 5 parcours sonores autour de Madame Bovary',
		site_name:   'Musair',
		url:         'https://expomadamebovary.musair.fr',
		images:      [
			{
				url:    'https://expomadamebovary.musair.fr/icons/bovary-open-graph.jpg',
				alt:    'Musair',
				width:  688,
				height: 314,
			},
		],
	},
};

export const musairSeo = {
	title:       'Musair',
	description: 'Parcours de découverte artistiques visuels et sonores, bains de saison, méditations par l\'art',

	canonical: frontendUrls[RELEASE_CHANNEL],

	openGraph: {
		type:        'website',
		title:       'Musair',
		description: 'Nouveau parcours Musair : Jean de La Fontaine, hors des sentiers battus. Projet labellisé 400 ans de La Fontaine.',
		site_name:   'Musair',
		url:         frontendUrls[RELEASE_CHANNEL],
		images:      [
			{
				url:    `${frontendUrls[RELEASE_CHANNEL]}/icons/musair-open-graph-la-fontaine.jpg`,
				alt:    'Musair',
				width:  688,
				height: 314,
			},
		],
	},
};

const defaultSeo = {
	additionalMetaTags: [
		// Apple
		{
			property: 'apple-mobile-web-app-capable',
			content:  'yes',
		},
		{
			property: 'apple-mobile-web-app-status-bar-style',
			content:  'default',
		},
		{
			property: 'apple-mobile-web-app-title',
			content:  'Musair',
		},

		// PWA
		{
			property: 'application-name',
			content:  'Musair',
		},
		{
			property: 'mobile-web-app-capable',
			content:  'yes',
		},
		{
			property: 'theme-color',
			content:  '#FFFFFF',
		},
	],
};

export function getSeo(domainEnum: DomainEnum) {
	if (domainEnum === DomainEnum.BOVARY) {
		return Object.assign(defaultSeo, bovarySeo);
	}
	return Object.assign(defaultSeo, {});
}
