import Image from 'next/image';
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import { Device, useDevice } from '../../../custom/useDevice';

interface Props {
	url: string;
	onClick: () => void;
}

const StyledImage = styled.div<{ device: Device }>`
	width: ${(props) => 48 / props.theme.fontSizeBase}rem;
	height: ${(props) => 48 / props.theme.fontSizeBase}rem;
	margin-left: ${(props) => 8 / props.theme.fontSizeBase}rem;
	margin-right: ${(props) => 16 / props.theme.fontSizeBase}rem;
	cursor: pointer;

	${(props) => props.device !== Device.MOBILE && css`
		margin-left: ${20 / props.theme.fontSizeBase}rem;
		width: ${56 / props.theme.fontSizeBase}rem;
		height: ${56 / props.theme.fontSizeBase}rem;
	`}

	img {
		object-fit: cover;
	}
`;

export const ImagePlayer: FunctionComponent<Props> = ({ url, onClick }: Props) => {
	const device = useDevice();

	return (
		<StyledImage device={device} onClick={onClick}>
			<Image
				src={url}
				width={device === Device.MOBILE ? 48 : 56}
				height={device === Device.MOBILE ? 48 : 56}
				priority={true} alt="image-player"
			/>
		</StyledImage>
	);
};
