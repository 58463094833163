import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconListenSVG = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="icon listen">
			<path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M2.06397 11.4714C2.24182 11.4714 2.386 11.6156 2.386 11.7934C2.386 17.8529 6.54252 22.678 11.4672 22.678C15.4133 22.678 18.7883 19.7152 20.0463 15.4381C20.0965 15.2675 20.2755 15.1698 20.4461 15.22C20.6167 15.2702 20.7144 15.4492 20.6642 15.6198C19.3459 20.1019 15.7652 23.3221 11.4672 23.3221C6.087 23.3221 1.74194 18.0998 1.74194 11.7934C1.74194 11.6156 1.88612 11.4714 2.06397 11.4714Z" fill={props.color} stroke={props.color} strokeWidth="0.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
			<path id="Vector" d="M4.25388 9.60353C4.64031 10.7628 5.79962 11.6645 6.95892 12.051C8.11823 12.5662 9.53516 12.3086 10.4368 11.4069C10.6945 11.0205 10.1792 10.634 9.9216 10.8917C9.14872 11.6645 8.11823 11.9221 7.08774 11.5357C6.18605 11.1493 5.28437 10.3764 4.89793 9.47472C4.76912 9.08829 4.12506 9.2171 4.25388 9.60353Z" fill={props.color} stroke={props.color} strokeWidth="0.4"/>
			<path id="Vector_2" d="M18.2943 12.3088C17.5214 13.0817 16.3621 13.3393 15.3316 13.2105C14.3011 13.2105 13.5283 12.5664 13.1418 11.6647C13.013 11.2783 12.369 11.4071 12.4978 11.7935C12.8842 12.9528 13.9147 13.7257 15.074 13.8545C16.3621 13.9833 17.9079 13.5969 18.6807 12.6952C19.0672 12.4376 18.6807 11.9223 18.2943 12.3088Z" fill={props.color} stroke={props.color} strokeWidth="0.4"/>
			<path id="Vector_3" d="M7.2165 16.817C8.11818 18.1051 9.4063 19.7797 11.2097 19.6508C12.7554 19.6508 14.0435 18.3627 14.6876 16.9458C14.8164 16.5594 14.3011 16.3017 14.1723 16.5594C13.6571 17.7187 12.6266 18.878 11.2097 18.878C9.66392 18.878 8.50461 17.461 7.73174 16.3017C7.60293 16.0441 6.95887 16.4306 7.2165 16.817Z" fill={props.color} stroke={props.color} strokeWidth="0.4"/>
			<path id="Vector_4" d="M5.54192 7.67142C6.70123 7.02736 7.86054 6.64092 9.14865 6.3833C9.27746 6.3833 9.40628 6.12568 9.40628 5.99686C9.40628 5.86805 9.14865 5.73924 9.01984 5.86805C7.73172 5.99686 6.44361 6.51211 5.2843 7.15617C4.89786 7.41379 5.15549 7.92904 5.54192 7.67142Z" fill={props.color} stroke={props.color} strokeWidth="0.4"/>
			<path id="Vector_5" d="M19.0672 10.1187C18.5519 8.83063 17.3926 8.05776 16.2333 7.4137C15.8469 7.15608 15.5892 7.80014 15.8469 7.92895C16.8774 8.44419 18.0367 9.08825 18.4231 10.2476C18.4231 10.3764 18.6807 10.5052 18.8095 10.5052C19.0672 10.5052 19.196 10.2476 19.0672 10.1187Z" fill={props.color} stroke={props.color} strokeWidth="0.4"/>
			<path id="Vector_6" d="M1.80641 7.28498C1.93522 7.41379 2.06403 7.5426 2.19285 7.67141C2.19285 7.80022 2.32166 7.80022 2.32166 7.92903H2.19285C2.32166 7.67141 2.45047 7.41379 2.57928 7.15616C2.70809 6.5121 2.96572 6.12567 3.22334 5.61042C3.73859 4.70874 4.51146 4.06468 5.28433 3.42062C6.95888 2.1325 9.01987 1.35963 11.0809 1.35963C15.074 1.35963 18.6808 3.93587 20.3553 7.41379C20.8706 8.44428 21.1282 9.47478 21.3858 10.6341C21.5146 11.7934 21.3858 12.9527 21.9011 13.9832C22.0299 14.2408 22.2875 14.112 22.4163 13.9832C22.8027 13.4679 23.0604 12.8239 22.6739 12.1798C22.5451 11.7934 21.9011 12.1798 22.1587 12.5663C22.4163 13.0815 22.1587 13.4679 21.9011 13.7256C22.0299 13.7256 22.2875 13.7256 22.4163 13.7256C22.0299 12.9527 22.0299 12.1798 22.0299 11.407C22.0299 10.7629 21.9011 10.1188 21.7722 9.60359C21.5146 8.44428 20.9994 7.28498 20.3553 6.25448C19.0672 4.19349 17.2638 2.64775 15.074 1.74607C10.308 -0.18611 4.76908 1.74607 2.32166 6.25448C2.06403 6.76973 0.775917 8.83072 1.93522 9.21715C2.32166 9.34596 2.83691 9.08834 2.96572 8.57309C3.09453 7.67141 2.70809 7.15616 2.19285 6.76973C1.93522 6.5121 1.54879 6.89854 1.80641 7.28498Z" fill={props.color} stroke={props.color} strokeWidth="0.4"/>
		</g>
	</svg>
));
