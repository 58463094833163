import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconNext = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M17.9517 2.48253C18.0717 5.78253 18.0317 9.09253 17.8317 12.3825C18.0817 12.2425 18.3317 12.0925 18.5817 11.9525C13.6817 9.26253 9.91171 4.84253 4.63171 2.79253C4.34171 2.68253 3.97171 2.97253 4.00171 3.28253C4.52171 8.76253 4.62171 14.2825 4.31171 19.7825C4.29171 20.0825 4.65171 20.3825 4.94171 20.2625C7.28171 19.2925 8.47171 16.9525 10.5917 15.6825C11.8817 14.9125 13.3817 14.5525 14.7817 14.0325C16.2617 13.4825 17.7117 12.8525 19.1317 12.1625C18.8817 12.0225 18.6317 11.8725 18.3817 11.7325C18.4717 15.0625 18.5817 18.3925 18.7017 21.7225C18.7217 22.3625 19.7217 22.3625 19.7017 21.7225C19.5817 18.3925 19.4717 15.0625 19.3817 11.7325C19.3717 11.3325 18.9717 11.1325 18.6317 11.3025C17.1817 12.0125 15.7017 12.6525 14.1817 13.1925C12.8717 13.6625 11.4917 14.0325 10.2717 14.7125C8.14171 15.9025 6.94171 18.3625 4.67171 19.3025C4.88171 19.4625 5.09171 19.6225 5.30171 19.7825C5.61171 14.2825 5.51171 8.77253 4.99171 3.28253C4.79171 3.44253 4.58171 3.60253 4.37171 3.76253C9.55171 5.77253 13.2717 10.1725 18.0817 12.8225C18.4317 13.0125 18.8117 12.7725 18.8317 12.3925C19.0317 9.09253 19.0717 5.79253 18.9517 2.49253C18.9217 1.84253 17.9217 1.83253 17.9517 2.48253Z" fill={props.color} stroke={props.color} strokeWidth="0.4"/>
	</svg>
));
