export enum ReleaseChannels {
	DEVELOPMENT = 'development',
	STAGING = 'staging',
	PRODUCTION = 'production'
}

export const frontendUrls = {
	[ReleaseChannels.DEVELOPMENT]: 'http://localhost:3000',
	[ReleaseChannels.STAGING]:     'https://test.musair.fr',
	[ReleaseChannels.PRODUCTION]:  'https://app.musair.fr',
};
