import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'next-i18next';

import { OnboardingText } from './OnboardingText';
import { OnboardingTitle } from './OnboardingTitle';
import Theme from '../../../styles/Theme';
import { IconListenSVG } from '../svg/IconListenSVG';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	padding-bottom: ${(props) => (36) / props.theme.fontSizeBase}rem;
`;

const StyledIconContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;

	align-self: center;

	svg {
		width: 100%;
		flex-grow: 0.4;
    	flex-basis: inherit;
	}
`;

const StyledContent = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledTexts = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	flex: 1;
	gap: ${(props) => (16) / props.theme.fontSizeBase}rem;
`;

export const OnboardingSlide2: FunctionComponent = () => {
	const { t } = useTranslation(['common']);

	return (
		<StyledContainer>
			<StyledContent>
				<StyledIconContainer>
					<IconListenSVG color={Theme.black3}/>
				</StyledIconContainer>
				<StyledTexts>
					<OnboardingTitle>
						{ t('common:onboarding2Title') }
					</OnboardingTitle>
					<OnboardingText>
						{ t('common:onboarding2Text') }
					</OnboardingText>
				</StyledTexts>
			</StyledContent>
		</StyledContainer>
	);
};
