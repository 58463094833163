import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconPlay = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.1626 11.2723C5.1626 13.3403 5.02589 15.4083 5.1626 17.4762C5.29931 19.1306 6.5297 20.2335 8.17021 19.9578C8.99047 19.8199 9.67402 19.4063 10.3576 18.9927C11.1778 18.4413 12.1348 17.8898 12.9551 17.4762C14.5956 16.649 16.3728 16.0976 18.0133 15.1325C19.3804 14.4432 20.4741 13.2024 19.7906 11.5481C19.107 9.89369 17.0564 9.20437 15.6893 8.51505C13.912 7.55 12.1348 6.58495 10.4943 5.6199C9.67402 5.20631 8.85376 4.65485 7.89679 4.24126C7.21324 3.96553 6.5297 3.82767 5.98286 4.37913C4.75247 5.75777 5.02589 8.10146 5.02589 9.89369C5.02589 10.9966 5.02589 12.0995 5.02589 13.2024C5.02589 14.0296 5.1626 14.8568 5.84615 15.4083C6.39299 15.8219 7.07653 15.684 7.07653 14.9947C7.07653 13.616 6.93982 12.3752 6.80312 10.9966C6.80312 10.1694 5.43602 10.1694 5.43602 10.9966C5.57273 12.3752 5.70944 13.616 5.70944 14.9947C6.11957 14.8568 6.5297 14.7189 6.93982 14.4432C6.39299 14.1675 6.39299 13.616 6.39299 13.0646C6.39299 12.2374 6.39299 11.2723 6.39299 10.4451C6.39299 9.06651 6.25628 7.55 6.5297 6.30922C6.66641 6.0335 6.80312 5.34418 6.93982 5.34418C6.93982 5.34418 7.48666 5.6199 7.62337 5.75777C8.30692 6.17136 8.99047 6.44709 9.67402 6.86068C11.0411 7.55 12.2715 8.23932 13.6386 9.06651C15.0057 9.75583 16.3728 10.4451 17.6032 11.2723C18.15 11.5481 18.8336 12.2374 18.5602 12.9267C18.4235 13.4782 17.7399 13.7539 17.1931 14.0296C14.5956 15.2704 11.9981 16.3733 9.4006 18.0277C8.71705 18.4413 8.0335 18.8549 7.21324 18.717C6.39299 18.4413 6.39299 17.6141 6.39299 16.9248C6.39299 15.1325 6.39299 13.2024 6.39299 11.4102C6.5297 10.4451 5.1626 10.4451 5.1626 11.2723Z" fill={props.color} />
	</svg>
));
