import { FunctionComponent, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import Theme from '../../styles/Theme';
import { DirectionProfiles } from '../../models/MapBox';

import { IconDistanceSVG } from './svg/IconDistanceSVG';
import { IconOnBikeSVG } from './svg/IconOnBikeSVG';
import { IconOnFootSVG } from './svg/IconOnFootSVG';
import { IconOnVehiculeSVG } from './svg/IconOnVehiculeSVG';
import { IconPlaySVG } from './svg/IconPlaySVG';

interface Props {
	className?: string;
	onClick: () => void;
	icon?: DirectionProfiles | 'play' | 'distance' | 'none';
	light?: boolean;
}

const StyledButton = styled.button<{ light: boolean }>`
	border: none;
	outline: 0;
	cursor: pointer;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: ${(props) => 48 / props.theme.fontSizeBase}rem;
	gap: ${(props) => 4 / props.theme.fontSizeBase}rem;

	border-radius: ${(props) => 40 / props.theme.fontSizeBase}rem;

	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 20 / props.theme.fontSizeBase}rem;

	${(props) => props.light && css`
		background: ${props.theme.white};
		color: ${props.theme.black};
		border: 1px solid ${props.theme.mapButtonLightBorder};
	`}

	${(props) => !props.light && css`
		background: ${props.theme.black};
		color: ${props.theme.white};
	`}
`;

export const MapButton: FunctionComponent<PropsWithChildren<Props>> = ({
	children, className, onClick, light = false, icon = 'none',
}: PropsWithChildren<Props>) => (
	<StyledButton light={light} onClick={onClick} className={className}>
		{ icon === 'walking' && <IconOnFootSVG width={24} height={24} color={light ? Theme.mapButtonLightBorder : Theme.white}/> }
		{ icon === 'cycling' && <IconOnBikeSVG width={24} height={24} color={light ? Theme.mapButtonLightBorder : Theme.white}/> }
		{ icon === 'driving' && <IconOnVehiculeSVG width={24} height={24} color={light ? Theme.mapButtonLightBorder : Theme.white}/> }
		{ icon === 'play' && <IconPlaySVG width={24} height={24} color={light ? Theme.mapButtonLightBorder : Theme.white}/> }
		{ icon === 'distance' && <IconDistanceSVG width={24} height={24} color={light ? Theme.mapButtonLightBorder : Theme.white}/> }

		{ children }
	</StyledButton>
);
