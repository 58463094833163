import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'next-i18next';
import { ThreeIcons } from '@/components/icon/onboarding/ThreeIcons';
import { OnboardingText } from './OnboardingText';
import { OnboardingTitle } from './OnboardingTitle';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
    width: 100%;

	padding-bottom: ${(props) => (36) / props.theme.fontSizeBase}rem;
`;

const StyledContent = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledIconContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;

	align-self: center;

	svg {
		width: 100%;
		flex-grow: 0.4;
		flex-basis: inherit; // To not let the parent grow
	}
`;

const StyledTexts = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	flex: 1;
	gap: ${(props) => (16) / props.theme.fontSizeBase}rem;
`;

export const OnboardingSlide1: FunctionComponent = () => {
	const { t } = useTranslation(['common']);

	return (
		<StyledContainer>
			<StyledContent>
				<StyledIconContainer>
					<ThreeIcons />
				</StyledIconContainer>
				<StyledTexts>
					<OnboardingTitle>
						{ t('common:onboarding1Title') }
					</OnboardingTitle>
					<OnboardingText>
						{ t('common:onboarding1Text') }
					</OnboardingText>
				</StyledTexts>
			</StyledContent>
		</StyledContainer>
	);
};
