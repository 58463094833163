import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { useEffect } from 'react';
import { install } from 'resize-observer';
import { appWithTranslation } from 'next-i18next';

import { GoogleTagManager } from '@next/third-parties/google';
import { gtmPageView } from '@/utils/gtm';
import { initializeClient } from '@/components/context/ClientContext';
import { Layout } from '../components/Layout';
import { Spinner } from '../components/common/Spinner';
import { getSeo } from '../next-seo.config';
import { NextPageProps } from '../typings/NextPageProps';
import { getDomainEnum } from '../utils/domains';
import { getHost } from '../utils/host';
import { logger } from '../utils/logger';

if (typeof window !== 'undefined' && !window.ResizeObserver) {
	logger.info('INSTALL resize-observer');
	install();
}

interface CustomAppProps extends AppProps<NextPageProps> {
	host: string;
}

declare global {
    interface Window {
		_axcb: {
			push: (callback: (axeptio: any) => void) => void;
		};
	}
}

function MyApp({
	Component, pageProps, host, router,
}: CustomAppProps) {
	const hasQueryParams = /\[.+\]/.test(router.route) || /\?./.test(router.asPath);
	const isReady = !hasQueryParams || Object.keys(router.query).length > 0;

	useEffect(() => {
		// Send event on first page load
		gtmPageView(window.location.pathname, document.title, initializeClient(pageProps.client.attributes.domain));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.isReady]);

	useEffect(() => {
		const handleRouteChange = (url: string) => {
			gtmPageView(url, document.title, initializeClient(pageProps.client.attributes.domain));

			window.scrollTo(0, 0);
		};
		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Layout isMap={(pageProps.client && pageProps.client.attributes.type === 'map')} client={pageProps.client || null}>
				<DefaultSeo {...getSeo(getDomainEnum(host))} />

				<Head>
					<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
				</Head>

				{ isReady && <Component {...pageProps} domainEnum={getDomainEnum(host)} host={host} /> }
				{ !isReady && <Spinner/> }

				<GoogleTagManager gtmId="GTM-WHH359P" />
			</Layout>
		</>
	);
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	const appProps = await App.getInitialProps(appContext);

	return {
		...appProps,
		host: getHost(appContext.ctx.req),
	};
};

export default appWithTranslation(MyApp);
