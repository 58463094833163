import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { IconNext } from '@/components/icon/IconNext';
import Theme from '../../../styles/Theme';

interface Props {
	disabled?: boolean;
	onClick: () => void;
}

const StyledButton = styled.button<{ disabled: boolean }>`
	border: none;
	background: none;
	cursor: pointer;
	outline: 0;
	position: relative;

	&:disabled {
		opacity: 0.16;
	}
`;

export const NextButton: FunctionComponent<Props> = ({ onClick, disabled = false }: Props) => (
	<StyledButton onClick={onClick} disabled={disabled}>
		<IconNext width={24} height={24} color={Theme.black} />
	</StyledButton>
);
