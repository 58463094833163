/* eslint-disable no-console */
export enum Levels {
	TRACE = 0,
	DEBUG = 1,
	INFO = 2,
	WARN = 3,
	ERROR = 4,
	SILENT = 5,
}

export class LoggerLib {
	private level = Levels.DEBUG;

	constructor(level?: Levels) {
		if (level) {
			this.level = level;
		}
	}

	trace(...args: unknown[]): void {
		if (Levels.TRACE >= this.level) {
			Reflect.apply(console.trace, null, args);
		}
	}

	debug(...args: unknown[]): void {
		if (Levels.DEBUG >= this.level) {
			Reflect.apply(console.debug, null, args);
		}
	}

	log(...args: unknown[]): void {
		if (Levels.DEBUG >= this.level) {
			Reflect.apply(console.info, null, args);
		}
	}

	info(...args: unknown[]): void {
		if (Levels.INFO >= this.level) {
			Reflect.apply(console.info, null, args);
		}
	}

	warn(...args: unknown[]): void {
		if (Levels.WARN >= this.level) {
			Reflect.apply(console.warn, null, args);
		}
	}

	error(...args: unknown[]): void {
		if (Levels.ERROR >= this.level) {
			Reflect.apply(console.error, null, args);
		}
	}
}
