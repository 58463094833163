import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconPause = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M16.1041 10.5909C15.9782 8.36499 15.8525 6.14308 16.0144 3.95712C16.1967 2.68096 18.0198 2.68096 17.8375 3.95712C17.6754 6.22583 17.8015 8.49455 17.9275 10.7633C18.0851 13.5992 18.2426 16.4351 17.8375 19.271C17.8375 19.8179 17.2906 20.0002 16.5613 20.0002C16.1967 19.8179 16.0144 19.4533 16.0144 18.9063C16.4198 16.17 16.2618 13.3773 16.1041 10.5909ZM6.89907 19.0883C7.446 14.1659 6.89907 9.06133 6.35215 4.13902C6.16984 3.04518 7.99292 3.04518 8.17523 4.13902C8.72215 9.06133 9.26907 14.1659 8.72215 19.0883C8.53984 20.1821 6.71677 20.1821 6.89907 19.0883Z" fill={props.color} />
	</svg>
));
