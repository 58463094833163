import { FunctionComponent } from 'react';
import ReactSlider from 'react-slider';
import styled, { css } from 'styled-components';

import { Device, useDevice } from '../../../custom/useDevice';

interface Props {
	onChange?: (value: number) => void;
	progress: number; // %
	isRound?: boolean;
}

const StyledSlider = styled(ReactSlider)<{ device: Device }>`
	width: 100%;
	height: ${(props) => 2 / props.theme.fontSizeBase}rem;

	${(props) => props.device !== Device.MOBILE && css`
		height: ${4 / props.theme.fontSizeBase}rem;
	`};
`;

const StyledSliderBig = styled(ReactSlider)<{ device: Device }>`
	width: 100%;
	height: ${(props) => 3 / props.theme.fontSizeBase}rem;

	${(props) => props.device !== Device.MOBILE && css`
		height: ${4 / props.theme.fontSizeBase}rem;
	`};
`;

const StyledThumb = styled.div<{ isRound?: boolean }>`
	height: ${(props) => 10 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 10 / props.theme.fontSizeBase}rem;
	width: ${(props) => 10 / props.theme.fontSizeBase}rem;
	top: -${(props) => 4 / props.theme.fontSizeBase}rem;
	text-align: center;
	background-color: ${(props) => props.theme.playerMainColor};
	cursor: grab;

	${(props) => props.isRound && css`
		border-radius: ${10 / props.theme.fontSizeBase}rem;
	`};
`;

const StyledTrack = styled.div<{ index: number }>`
	top: 0;
	bottom: 0;
	background: ${(props) => (props.index === 1 ? props.theme.grey : props.theme.playerMainColor)};
	border-radius: 999px;
`;

export const PlayerSlider: FunctionComponent<Props> = ({
	onChange, progress, isRound,
}: Props) => {
	const device = useDevice();

	if (!onChange) {
		return (
			<StyledSliderBig
				device={device}
				max={100}
				min={0}
				value={progress}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				renderTrack={(props, state) => <StyledTrack {...props} index={state.index} />}
			/>
		);
	}

	return (
		<StyledSlider
			device={device}
			max={100}
			min={0}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			onChange={(v: number) => onChange(v)}
			value={progress}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			renderTrack={(props, state) => <StyledTrack {...props} index={state.index} />}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			renderThumb={(props) => <StyledThumb isRound={isRound} {...props} />}
		/>
	);
};
