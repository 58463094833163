import { IncomingMessage } from 'http';

export function getHost(req?: IncomingMessage): string {
	let host = (req?.headers ? req.headers.host : window.location.host) || '';

	if (req && req.headers['x-forwarded-host'] && typeof req.headers['x-forwarded-host'] === 'string') {
		host = req.headers['x-forwarded-host'];
	}

	return host;
}
