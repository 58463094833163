import { ReleaseChannels } from './typings/releaseChannels';

// Release channel
export const RELEASE_CHANNEL: ReleaseChannels = process.env.NEXT_PUBLIC_RELEASE_CHANNEL as ReleaseChannels;

// API
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const API_KEY = process.env.NEXT_PUBLIC_API_KEY;

// MapBox
export const MAPBOX_KEY = process.env.NEXT_PUBLIC_MAPBOX_KEY as string;
