import { FunctionComponent, useCallback, useState } from 'react';
import styled from 'styled-components';
import type { Swiper as SwiperType } from 'swiper';
import SwiperCore, {
	Pagination,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'next-i18next';

import { LocalStorageKeys, localStorageSetItem } from '../../../data/LocalStorage';
import { MapStore } from '../../../data/MapStore';
import { MapButton } from '../MapButton';
import { LogoSVG } from '../../logo/LogoSVG';

import { OnboardingSlide1 } from './OnboardingSlide1';
import { OnboardingSlide2 } from './OnboardingSlide2';
import { OnboardingSlide3 } from './OnboardingSlide3';
import { OnboardingSlide4 } from './OnboardingSlide4';
import { OnboardingSlide5 } from './OnboardingSlide5';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
import { Device, useDevice } from '../../../custom/useDevice';

SwiperCore.use([Pagination]);

const StyledContainer = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.82);
	z-index: 20;
	padding: ${(props) => 28 / props.theme.fontSizeBase}rem;

	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
`;

const StyledFixIos = styled.div`
	display: flex;
    flex-direction: column;
	flex: 1;
`;

const StyledContent = styled.div`
	border-radius: ${(props) => 7 / props.theme.fontSizeBase}rem;
	background-color: ${(props) => props.theme.white};
	padding: ${(props) => 35 / props.theme.fontSizeBase}rem ${(props) => 19 / props.theme.fontSizeBase}rem ${(props) => 38 / props.theme.fontSizeBase}rem ${(props) => 19 / props.theme.fontSizeBase}rem;

	display: flex;
	flex-direction: column;
	flex: 1;
	gap: ${(props) => 32 / props.theme.fontSizeBase}rem;

	.swiper {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.swiper-slide {
		width: 100%;
		height: 100%;
		cursor: grab;
	}

	.swiper-pagination-bullets {
		bottom: 0px;
	}

	.swiper-pagination-bullet {
		width: ${(props) => 18 / props.theme.fontSizeBase}rem;
		height: ${(props) => 3 / props.theme.fontSizeBase}rem;
		background: ${(props) => props.theme.onboardingPaginationNotActive};
		opacity: 1;
		border-radius: 0px;
	}

	.swiper-pagination-bullet-active {
		width: ${(props) => 34 / props.theme.fontSizeBase}rem;
		height: ${(props) => 3 / props.theme.fontSizeBase}rem;
		background: ${(props) => props.theme.onboardingPaginationActive};
		border-radius: 0px;
	}
`;

const StyledSwiperContainer = styled.div`
	display: flex;
	flex: 1;
	overflow: hidden;
`;

const StyledFooter = styled.div`
	display: flex;
	justify-content: center;
	flex: 0.1;
    align-items: flex-end;
`;

const StyledToPass = styled.p`
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.white};
	cursor: pointer;
`;

const StyledButton = styled(MapButton)<{$isDesktop: boolean}>`
	align-self: center;
	width: calc(100% - ${(props) => ((42 - 19) * 2) / props.theme.fontSizeBase}rem);
	padding: ${(props) => 8 / props.theme.fontSizeBase}rem 0;

	${(props) => props.$isDesktop && `
		width: auto;
		padding: ${8 / props.theme.fontSizeBase}rem ${32 / props.theme.fontSizeBase}rem;
	`};
`;

const StyledCheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 20 / props.theme.fontSizeBase}rem;

	label {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	input {
		margin: 0 ${(props) => 10 / props.theme.fontSizeBase}rem 0 0;
		width: ${(props) => 14 / props.theme.fontSizeBase}rem;
		height: ${(props) => 14 / props.theme.fontSizeBase}rem;
		cursor: pointer;
	}
`;

const StyledLogo = styled(LogoSVG)`
	align-self: center;
`;

export const Onboarding: FunctionComponent = () => {
	const { t } = useTranslation(['common']);
	const device = useDevice();

	const [swiper, setSwiper] = useState<SwiperType | null>(null);
	const [isLast, setIsLast] = useState(false);
	const [savePreference, setSavePreference] = useState(false);

	const onNext = useCallback(() => {
		if (isLast) {
			if (savePreference) {
				localStorageSetItem(LocalStorageKeys.ONBOARDING, false);
			}
			MapStore.update((s) => {
				s.displayOnboarding = false;
			});
		} else if (swiper) {
			swiper.slideNext();
		}
	}, [isLast, savePreference, swiper]);

	const onPass = useCallback(() => {
		MapStore.update((s) => {
			s.displayOnboarding = false;
		});
	}, []);

	const onSlideChange = useCallback((slider: SwiperType) => {
		if (slider.realIndex === 4) {
			setIsLast(true);
		} else {
			setIsLast(false);
		}
	}, []);

	const onCheckboxChange = useCallback(() => {
		setSavePreference((prev) => (!prev));
	}, []);

	return (
		<StyledContainer>
			<StyledFixIos>
				<StyledContent>
					<StyledLogo width={114}/>
					<StyledSwiperContainer>
						<Swiper
							onSwiper={setSwiper}
							pagination={true}
							onSlideChange={onSlideChange}
						>
							<SwiperSlide>
								<OnboardingSlide1/>
							</SwiperSlide>
							<SwiperSlide>
								<OnboardingSlide2/>
							</SwiperSlide>
							<SwiperSlide>
								<OnboardingSlide3/>
							</SwiperSlide>
							<SwiperSlide>
								<OnboardingSlide4/>
							</SwiperSlide>
							<SwiperSlide>
								<OnboardingSlide5/>
							</SwiperSlide>
						</Swiper>
					</StyledSwiperContainer>

					<StyledButton onClick={onNext} icon="distance" $isDesktop={device === Device.DESKTOP || device === Device.TABLET_LANDSCAPE}>
						{ !isLast && t('common:buttonNext')}
						{ isLast && t('common:buttonDiscover')}
					</StyledButton>

					{ isLast && <StyledCheckboxContainer>
						<label><input type="checkbox" onChange={onCheckboxChange} />{t('common:onboardingDoNotShowNextTime')}</label>
					</StyledCheckboxContainer> }
				</StyledContent>

				{ !isLast && <StyledFooter>
					<StyledToPass onClick={onPass}>
						{ t('common:buttonSkip') }
					</StyledToPass>
				</StyledFooter> }
			</StyledFixIos>
		</StyledContainer>
	);
};
