import { DefaultTheme } from 'styled-components';

const Theme : DefaultTheme = {
	fontSizeBase:  16,
	fontSizeSmall: 14,

	textPrimary: '#3F4234',
	borderColor: '#E1E1E1',

	black:     '#000',
	black2:    '#626262',
	black3:    '#171104',
	white:     '#FFF',
	orange:    '#DE5D3E',
	grey:      '#E3E3E3',
	fieldDrab: '#66541E',
	goldenRod: '#D2A928',

	// BUTTONS
	btnPrimary: '#DE5D3E',
	btnLight:   '#3F4234',

	// MAP
	mapOrange:    '#E04300',
	mapBlack:     '#010101',
	mapSeparator: '#DFDFDF',

	mapCapsulesText:  '#B3B3B3',
	mapDirectionText: '#171104',

	mapButtonLightBorder:       '#171104',
	mapButtonNotSelectedBorder: '#E2E2E2',

	// SLIDER (images)
	sliderLastSlideBackground:       '#3F4234',
	sliderLastSlideSecondBackground: '#383A2D',
	sliderLastSlideThirdBackground:  '#2F3126',

	sliderLastSlideTopBottom:  '#DE5D3E',
	sliderPaginationActive:    '#DE5D3E',
	sliderPaginationNotActive: '#F4E7D7',

	// PLAYER
	playerMainColor: '#DE5D3E',

	distanceSelected: '#DE5D3E',

	// ONBOARDING
	onboardingIconBackground:      '#1D1D1B',
	onboardingPaginationActive:    '#000',
	onboardingPaginationNotActive: '#DADADA',

	// MENU
	menuBackground: '#F9F7F7',
	menuLightText:  '#6D6D6D',

	/**
	 * @name withOpacity
	 * @description add opacity to hexcolor
	 * @param {string} hexColor - the hexColor to convert
	 * @param {float} opacity - the opacity to apply
	 * @return {string} The hexcolor with opacity
	 */
	withOpacity(hexColor: string, opacity: number): string {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);

		if (result) {
			return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`;
		}

		return '';
	},
};

export default Theme;
