import { useRouter } from 'next/router';
import { PlayerStore } from '@/data/PlayerStore';
import { Device, useDevice } from '@/custom/useDevice';
import { useCallback, useEffect, useState } from 'react';
import { ResponsiveStore } from '@/data/ResponsiveStore';
import styled, { css } from 'styled-components';
import { gtmEventDemarrage, gtmEventPlayer } from '@/utils/gtm';
import { captureException } from '@sentry/nextjs';
import { useAudioPlayer, AudioPlayer } from 'react-hl-audio';
import { Duration } from '../common/Duration';
import { ImagePlayer } from '../common/player/ImagePlayer';
import { PlayerSlider } from '../common/player/PlayerSlider';
import { PrevButton } from '../common/player/PrevButton';
import { PlayPauseButton } from '../common/player/PlayPauseButton';
import { NextButton } from '../common/player/NextButton';
import { SeekBackwardButton } from '../common/player/SeekBackwardButton';
import { SeekForwardButton } from '../common/player/SeekForwardButton';
import { useClient } from '../context/ClientContext';

interface Props {
	fixed: boolean;
}

interface GEvent {
	percent30: boolean;
	percent60: boolean;
	percent90: boolean;
}

const PlayerFixedContainer = styled.div<{ device: Device }>`
	height: ${(props) => 96 / props.theme.fontSizeBase}rem;
	width: 100%;
	background: ${(props) => props.theme.white};

	${(props) => (props.device === Device.MOBILE || props.device === Device.TABLET_PORTRAIT) && css`
		height: ${64 / props.theme.fontSizeBase}rem;
		position: fixed;
		bottom: 0;
		z-index: 10;
	`}
`;

const PlayerContainer = styled.div`
	// margin: ${(props) => 31 / props.theme.fontSizeBase}rem 0 0 0;
	padding: ${(props) => 20 / props.theme.fontSizeBase}rem;

	display: flex;
	align-items: center;
	flex-direction: column;

	width: 100%;
	position: fixed;
	bottom: 0;
`;

const StyledButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const StyledControlsTimers = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	margin-top: ${(props) => 5 / props.theme.fontSizeBase}rem;
	margin-bottom:  ${(props) => 6 / props.theme.fontSizeBase}rem;
`;

const StyledText = styled.div`
	margin-right: ${(props) => 19 / props.theme.fontSizeBase}rem;
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledName = styled.span`
	font-weight: 900;
	font-size: ${(props) => 16 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 24 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.playerMainColor};

	overflow: hidden;
	text-overflow: ellipsis;

	line-clamp: 2;
	box-orient: vertical;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

const StyledInfos = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	background: ${(props) => props.theme.white};
`;

const StyledControlsFixed = styled.div`
	margin-right: ${(props) => 20 / props.theme.fontSizeBase}rem;
	height: 100%;
    display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10%;
`;

// DESKTOP
const PlayerFooterDesktop = styled.div`
	display: flex;
	flex-direction: row;
	// height: ${(props) => 96 / props.theme.fontSizeBase}rem;
	padding: ${(props) => 20 / props.theme.fontSizeBase}rem;
	width: 100%;
	box-shadow: 0 -${(props) => 4 / props.theme.fontSizeBase}rem ${(props) => 24 / props.theme.fontSizeBase}rem rgba(0, 0, 0, 0.02);
`;

const StyledInfosDesktop = styled.div`
	flex: 0.3;
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const StyledControlsDesktop = styled.div`
	flex: 0.7;

	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledControlsDesktopSlider = styled.div`
	flex: 1;
	margin: 0 ${(props) => 16 / props.theme.fontSizeBase}rem;
`;

const StyledDurationLeftDesktop = styled(Duration)`
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	font-weight: bold;
`;

const StyledDurationRightDesktop = styled(Duration)`
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	font-weight: bold;
`;

const StyledControlsDesktopButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: ${(props) => 57 / props.theme.fontSizeBase}rem;

	button:nth-of-type(2) {
		margin: 0 ${(props) => 36 / props.theme.fontSizeBase}rem 0 ${(props) => 36 / props.theme.fontSizeBase}rem;
	}
`;

const StyledEmptyDesktop = styled.div`
	flex: 0.15;
`;

const StyledExperienceTitle = styled.span`
	font-weight: 900;
	font-size: ${(props) => 10 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
`;

const ClassicPlayer = ({ fixed }: Props) => {
	const router = useRouter();
	const audio = PlayerStore.useState((s) => s.audio);
	const capsuleAnalyticsEvent = PlayerStore.useState((s) => s.capsuleAnalyticsEvent);
	const currentExperienceSlug = PlayerStore.useState((s) => s.currentExperienceSlug);
	const currentMediaIndex = PlayerStore.useState((s) => s.currentMediaIndex);
	const prevMediaIndex = PlayerStore.useState((s) => s.prevMediaIndex);
	const nextMediaIndex = PlayerStore.useState((s) => s.nextMediaIndex);
	const device = useDevice();

	const { client } = useClient();

	const goToCapsule = useCallback(() => {
		router.push(`/parcours/${currentExperienceSlug}/capsule/${currentMediaIndex}`);
	}, [currentExperienceSlug, currentMediaIndex, router]);

	const onRefCallback = useCallback((node: HTMLDivElement) => {
		if (node) {
			ResponsiveStore.update((s) => {
				s.footerHeight = node.getBoundingClientRect().height;
				return s;
			});
		}
	}, []);

	// GA EVENTS
	const [gEvents, setGEvents] = useState<GEvent>({
		percent30: false,
		percent60: false,
		percent90: false,
	});

	const onFirstPlay = useCallback(() => {
		if (capsuleAnalyticsEvent) {
			// createEvent({ action: `demarrage_capsule_${capsuleAnalyticsEvent}` });
			gtmEventDemarrage({
				event_group: 'capsule',
				event:       'demarrage',
				client,
				name:        capsuleAnalyticsEvent,
			});
		} else {
			captureException(new Error('NO_ANALYTICS_EVENT'));
		}
	}, [capsuleAnalyticsEvent, client]);

	const onProgress = useCallback((percent: number) => {
		if (capsuleAnalyticsEvent) {
			if (percent >= 30 && !gEvents.percent30) {
				gtmEventPlayer({
					event_group: 'capsule',
					event:       'capsule_30',
					client,
					name:        capsuleAnalyticsEvent,
				});
				setGEvents((prev) => ({ ...prev, percent30: true }));
			} if (percent >= 60 && !gEvents.percent60) {
				gtmEventPlayer({
					event_group: 'capsule',
					event:       'capsule_60',
					client,
					name:        capsuleAnalyticsEvent,
				});
				setGEvents((prev) => ({ ...prev, percent60: true }));
			} if (percent >= 90 && !gEvents.percent90) {
				gtmEventPlayer({
					event_group: 'capsule',
					event:       'capsule_90',
					client,
					name:        capsuleAnalyticsEvent,
				});
				setGEvents((prev) => ({ ...prev, percent90: true }));
			}
		} else {
			captureException(new Error('NO_ANALYTICS_EVENT'));
		}
	}, [capsuleAnalyticsEvent, client, gEvents.percent30, gEvents.percent60, gEvents.percent90]);

	useEffect(() => {
		setGEvents((prev) => ({
			...prev, percent30: false, percent60: false, percent90: false,
		}));
	}, [currentExperienceSlug, currentMediaIndex]);

	// PLAYER ACTIONS
	const {
		playing, currentTime, progress, duration, play, pause, seekTo,
	} = useAudioPlayer();

	const onPlayPause = useCallback(() => {
		if (playing) {
			pause();
		} else {
			play();
		}
	}, [pause, play, playing]);

	const onChange = useCallback((value: number) => {
		seekTo((duration / 100) * value);
	}, [duration, seekTo]);

	const onPrevious = useCallback(() => {
		if (prevMediaIndex !== null && currentExperienceSlug !== null) {
			pause();
			router.push(`/parcours/${currentExperienceSlug}/capsule/${prevMediaIndex}`);
		}
	}, [currentExperienceSlug, pause, prevMediaIndex, router]);

	const onBackward = useCallback(() => {
		if (currentTime) {
			if (currentTime - 15 < 0) {
				seekTo(0);
			} else {
				seekTo(currentTime - 15);
			}
			play();
		}
	}, [currentTime, play, seekTo]);

	const onForward = useCallback(() => {
		if (currentTime && duration) {
			if (currentTime + 15 > duration) {
				seekTo(duration);
			} else {
				seekTo(currentTime + 15);
			}
			play();
		}
	}, [currentTime, duration, play, seekTo]);

	const onNext = useCallback(() => {
		if (nextMediaIndex !== null && currentExperienceSlug !== null) {
			pause();
			setGEvents((prev) => ({
				...prev, percent30: false, percent60: false, percent90: false,
			}));
			router.push(`/parcours/${currentExperienceSlug}/capsule/${nextMediaIndex}`);
		}
	}, [currentExperienceSlug, nextMediaIndex, pause, router]);

	if (!audio) {
		return null;
	}

	return (
		<>
			<AudioPlayer
				src={audio.url}
				autoPlay={true}
				onProgress={onProgress}
				onFirstPlay={onFirstPlay}
			/>
			{ (device === Device.DESKTOP || device === Device.TABLET_LANDSCAPE) && <PlayerFooterDesktop
				ref={onRefCallback}>
				<StyledInfosDesktop onClick={goToCapsule}>
					<ImagePlayer onClick={goToCapsule} url={audio.image.attributes.url}/>

					<StyledText onClick={goToCapsule}>
						<StyledExperienceTitle>{ audio.name }</StyledExperienceTitle>
						<StyledName>{ audio.title }</StyledName>
					</StyledText>
				</StyledInfosDesktop>

				<StyledControlsDesktop>
					<StyledDurationLeftDesktop seconds={currentTime} />
					<StyledControlsDesktopSlider>
						<PlayerSlider progress={progress} onChange={onChange} isRound={true}/>
					</StyledControlsDesktopSlider>
					<StyledDurationRightDesktop seconds={duration} />
					<StyledControlsDesktopButtons>
						<PrevButton onClick={onPrevious} disabled={prevMediaIndex === null}/>
						<PlayPauseButton light={false} onClick={onPlayPause} playing={playing} />
						<NextButton onClick={onNext} disabled={nextMediaIndex === null}/>
					</StyledControlsDesktopButtons>
				</StyledControlsDesktop>

				{ device === Device.DESKTOP && <StyledEmptyDesktop/> }
			</PlayerFooterDesktop> }

			{ fixed && (device === Device.MOBILE || device === Device.TABLET_PORTRAIT)
			&& <PlayerFixedContainer ref={onRefCallback} device={device}>
				<PlayerSlider progress={progress} onChange={onChange} />
				<StyledInfos>
					<ImagePlayer onClick={goToCapsule} url={audio.image.attributes.url}/>

					<StyledText onClick={goToCapsule}>
						<StyledName>{ audio.name }</StyledName>
					</StyledText>

					<StyledControlsFixed>
						<PlayPauseButton light={true} onClick={onPlayPause} playing={playing} />
						<SeekBackwardButton onClick={onBackward}/>
					</StyledControlsFixed>
				</StyledInfos>
			</PlayerFixedContainer> }

			{ !fixed && (device === Device.MOBILE || device === Device.TABLET_PORTRAIT)
			&& <PlayerContainer ref={onRefCallback}>
				<PlayerSlider progress={progress} onChange={onChange}/>
				<StyledControlsTimers>
					<Duration seconds={currentTime} />
					<Duration seconds={duration} />
				</StyledControlsTimers>

				<StyledButtons>
					<PrevButton onClick={onPrevious} disabled={prevMediaIndex === null}/>
					<SeekBackwardButton onClick={onBackward}/>

					<PlayPauseButton onClick={onPlayPause} playing={playing} />

					<SeekForwardButton onClick={onForward}/>
					<NextButton onClick={onNext} disabled={nextMediaIndex === null}/>
				</StyledButtons>
			</PlayerContainer> }
		</>
	);
};

export default ClassicPlayer;
