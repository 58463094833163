export enum DomainEnum {
	BOVARY,
	MBAARRAS,
	MUSAIR,
	PARCOURS_SACRE_EN_BRIE,
	QUELMER,
	HIDDEN,
}

function isBovary(host: string): boolean {
	return host === 'expomadamebovary.musair.fr' || host === 'bovarytest.musair.fr';
}

function isMbaarras(host: string): boolean {
	return host === 'mbaarrastest.musair.fr' || host === 'mbaarras.musair.fr';
}

function isMusair(host: string): boolean {
	return host === 'test.musair.fr' || host === 'app.musair.fr';
}

function isParcoursSacreEnBrie(host: string): boolean {
	return host === 'parcourssacresenbrie.musair.fr';
}

function isQuelmer(host: string): boolean {
	return host === 'quelmer-adramar.musair.fr';
}

function isHidden(host: string): boolean {
	return host === 'b62oypa4.musair.fr';
}

export function getDomainEnum(host?: string): DomainEnum {
	if (!host) { return DomainEnum.MUSAIR; }
	if (isMbaarras(host)) { return DomainEnum.MBAARRAS; }
	if (isMusair(host) || isBovary(host)) { return DomainEnum.MUSAIR; }
	if (isParcoursSacreEnBrie(host)) { return DomainEnum.PARCOURS_SACRE_EN_BRIE; }
	if (isQuelmer(host)) { return DomainEnum.QUELMER; }

	if (isHidden(host)) { return DomainEnum.HIDDEN; }

	return DomainEnum.MUSAIR;
}
