import { Store } from 'pullstate';

import { Capsule } from '../models/Capsule';

export type MapStoreDisplay = 'pin' | 'parcours' | 'none';

interface MapStoreProps {
	heightOffsetButtons: number;
	display: MapStoreDisplay;
	nbParcours: number;
	parcoursSelected: string | null;
	capsuleSelected: Capsule | null;

	displayOnboarding: boolean;

	timeWalking: number;
	timeCycling: number;
	timeDriving: number;

	// eslint-disable-next-line no-undef
	userLocation: GeolocationCoordinates | null;
	userLocationError: number;

	mapPath: boolean;
	mapWalk: boolean;
	mapBike: boolean;
	mapCar: boolean;
}

export const MapStore = new Store<MapStoreProps>({
	heightOffsetButtons: 0,
	display:             'parcours',
	nbParcours:          0,
	parcoursSelected:    null,
	capsuleSelected:     null,
	displayOnboarding:   false,

	timeWalking: 0,
	timeCycling: 0,
	timeDriving: 0,

	userLocation:      null,
	userLocationError: 0,

	// Path / Profile configuration
	mapPath: false,
	mapWalk: false,
	mapBike: false,
	mapCar:  false,
});
