import { useCallback, useEffect, useState } from 'react';

export enum Device {
	MOBILE,
	TABLET_PORTRAIT,
	TABLET_LANDSCAPE,
	DESKTOP,
}

export function useDevice() {
	const [device, setDevice] = useState<Device>(Device.MOBILE);

	const setDeviceType = useCallback(() => {
		// Mobile
		if (window.matchMedia('(max-width: 767px)').matches) {
			setDevice(Device.MOBILE);
		}
		// Tablet portrait
		if (window.matchMedia('(min-width: 768px) and (max-width: 1280px) and (orientation: portrait)').matches) {
			setDevice(Device.TABLET_PORTRAIT);
		}
		// Tablet landscape
		if (window.matchMedia('(min-width: 768px) and (max-width: 1280px) and (orientation: landscape)').matches) {
			setDevice(Device.TABLET_LANDSCAPE);
		}
		// Desktop
		if (window.matchMedia('(min-width: 1281px)').matches) {
			setDevice(Device.DESKTOP);
		}
	}, []);

	useEffect(() => {
		setDeviceType();

		window.addEventListener('resize', setDeviceType);

		return () => window.removeEventListener('resize', setDeviceType);
	}, [setDeviceType]);

	return device;
}
