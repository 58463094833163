export enum LocalStorageAudioState {
	NOT_STARTED,
	STARTED,
	FINISHED,
}

export interface LocalStorageAudioStat {
	id: number;
	state: LocalStorageAudioState;
	remainingTime: number;
}

export enum LocalStorageKeys {
	ONBOARDING = 'onboarding', // Display or not the onboarding slider
	AUDIO_STATS = 'audio_stats', // Save the stats of each Audio (not-started, pending, finished)
}

type LocalStorageValue = boolean | number | string | LocalStorageAudioStat[];

export function localStorageSetItem(key: LocalStorageKeys, value: LocalStorageValue): void {
	localStorage.setItem(key, JSON.stringify(value));
}

export function localStorageGetItem<T>(key: LocalStorageKeys, defaultValue: LocalStorageValue): T {
	return JSON.parse(localStorage.getItem(key.toString()) || defaultValue.toString());
}
